// [[file:~/Documents/Acadamaze/2Marketing/1OnlineDigital/0Website/don/acdmz-www.org::acdmz-background-js][acdmz-background-js]]
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "@emotion/styled";  // import styled when actually needed

import BackgroundImage from "gatsby-background-image";

// let Background = styled.html`
//   height: 100%;
//   width: 100%;
//   background: #fff no-repeat top center fixed;
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   -o-background-size: cover;
//   background-size: cover;
// `;

// export default (Background = ({ className }) => (
const Background = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(
          relativePath: {
            eq: "Acadamaze_tree_final_A4letterhead_watermark_template.png"
          }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
          <StyledWrapper>
        <BackgroundImage
          /* Tag="html" */
          className={className}
          fluid={imageData}
          /* backgroundColor={`#040e18`} */
          /* backgroundColor={`#fff`} */
          style={{  // UNCOMMENT if not working
            // background: `no-repeat top center fixed`,
            // height: `100vh`  // UNCOMMENT IF NOT WORKING
          height: `100%`  // UNCOMMENT IF NOT WORKING
           }}  // UNCOMMENT IF NOT WORKING
          classId="gbi"
        >
          {/* <h1>Hello gatsby-background-image</h1> */}
          { children }
        </BackgroundImage>
              </StyledWrapper>
      );
    }}
  />
);
export default Background;

const StyledWrapper = styled.div`
width: 100%;
height: 100vh;
`

// const StyledBackground = styled(Background)`
//   // width: 100%;
//   // width: 100vw;
//   // height: 100vh;
//   // background-position: top center;
//   // display: flex;
//   // background-repeat: repeat-y;
// //
//   // height: 100%;
//   // background: #fff no-repeat top center fixed;
//   // background: no-repeat top center fixed;
//   // -webkit-background-size: cover;
//   // -moz-background-size: cover;
//   // -o-background-size: cover;
//   // background-size: cover;
// //
//   // .gatsby-background-image-gbi:after,
//   // .gatsby-background-image-gbi:before {
//   //   // background-position: top right;
//   //   // background-size: cover;
//   //   // background: no-repeat top center fixed;
//   // }
// `;
// //
// export default StyledBackground;
// acdmz-background-js ends here
