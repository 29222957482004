// [[file:~/Documents/Acadamaze/2Marketing/1OnlineDigital/0Website/don/acdmz-www.org::acdmz-index-js][acdmz-index-js]]
import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
// import styled from "styled-components";  // for gatsby-background-image
// import styled from "@emotion/styled"; // for gatsby-background-image
// import BackgroundImage from "gatsby-background-image"; // for gatsby-background-image

import Background from "../components/background";

export default ({ data }) => (
  <div>
    <Background>
      <div className="row">
        <div className="col-md-12">
          <div id="content">
            <Img
              title="Acadamaze logo"
              alt="Acadamaze growth and prosperity tree"
              fixed={data.file.childImageSharp.fixed}
              /* actually, got it working by using style of wrapper of img and compensating for the additional wrapper, i.e. using margin-bottom of 13px instead of 20px */
              imgStyle={{ marginBottom: `20px`, objectFit: `contain` }}
              style={{ marginBottom: `13px`, maxWidth: `100%` }}
              /* backgroundColor={true} */
            />
            <h1 id="headline">
              <strong>
                Private Tuition Online in Science, English and Maths
              </strong>
            </h1>
            <div id="description">
              <p>&nbsp;</p>
              <h3>
                <span style={{ color: `#0000ff` }}>
                  <strong>GET</strong>
                </span>{" "}
                <span style={{ color: `#000000` }}>
                  <strong>YOUR</strong>
                </span>{" "}
                <span style={{ color: `#00e600` }}>
                  <strong>FREE</strong>
                </span>{" "}
                <br/>
                <br/>
                Demonstration and Consultation
              </h3>
              <br/>
              <p>
                <a id="book" href="https://kutt.it/AcadamazeFreeDemo">
                  <strong>BOOK NOW</strong>
                </a>
              </p>
              <p>&nbsp;</p>
              <p>
                T: 020 33 93 44 83 • M: 075 653 953 27 • E:
                info[at]acadamaze[dot]com
                <br />
                Copyright © {new Date().getFullYear()} Acadamaze | Activating Academic Aspirations
              </p>
            </div>
          </div>
        </div>
      </div>
    </Background>
  </div>
);

export const query = graphql`
  query {
    file(
      relativePath: {
        eq: "Acadamaze_logo_final_A4letterhead_logo_template.png"
      }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 156) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;
// acdmz-index-js ends here
